<template>
  <div key="forwarderKey">
    <div class="div_title">
      <context :context=cate />
      <span>
        <InputText v-model="fowarderKeyword" v-on:keyup.enter="search({type:'SELECT_ORDER_BY_FORWARDER'})" placeholder="검색어" size="small" class="input-keyword"/>  
        <Button label="조회" v-on:click="search({type:'SELECT_ORDER_BY_FORWARDER'})" style="" raised size="small" ></Button>
      </span>
    </div>
  </div>
  <div class="card" >
    <div id="app-notice" v-if="forwarderOrders.length === 0" class="no-result"> 
      조회 내역이 없습니다.
    </div>
    <div v-else>
      <DataTable stripedRows v-model:expandedRows="expandedRows" v-model:selection="forwarderOrders" :value="forwarderOrders" dataKey="IDX" @rowExpand="onRowExpand" >
        <Column expander header="상세" style="max-width:1;background-color:white;color:black;" />
        <Column field="IDX" header="IDX" style="background-color: white;color:black;min-width:80px;"/>
        <Column header="국가" field="COUNTRY" style="background-color: white;color:black;min-width:70px;"/>
        <Column header="상품명" style="background-color: white;color:black;" >
          <template #body="slotProps">
            <div class="div_title3">
              {{ slotProps.data.NAME }} 
              <i style="color:red;" v-if="slotProps.data.NOTICE.indexOf('입금완료') !== -1 || slotProps.data.NOTICE.indexOf('결제완료') !== -1 || slotProps.data.NOTICE.indexOf('정산완료') !== -1" class="pi pi-dollar" ></i>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps" >
          <forwarderc :regDate="String(slotProps.data.REG_DATE).substring(0,8)" :idx="slotProps.data.IDX" :trackingNumber="slotProps.data.TRACKING_NUMBER" :notice="slotProps.data.NOTICE" :url="slotProps.data.URL" :imgUrl="slotProps.data.IMG_URL" :status="slotProps.data.STATUS" />
        </template>
      </DataTable>
      <br>
      <div class="div_title">
        <label v-if="isExpandAll === false" class="link" style="font-weight: normal;" @click="expandAll()">전체보기</label>
        <label v-else class="link" style="font-weight: normal;" @click="expandAll()">전체접기</label>
        <span>
          <label @click="paging('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{page}}</span>
          <label @click="paging('next')" class="link">
            다음
          </label>
        </span>
        <label @click="excel()" class="link" style="font-weight: normal;">Excel</label>
      </div>
    </div>
  </div>
  <div class="ad-container" style="width:100%;text-align: center;">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>

<script>
import { ref, reactive, inject } from 'vue'
import ssr from '@/components/common/ssr'
import context from '@/components/common/Context.vue'
import router from '@/router'
import forwarderc from '@/components/content/forwarder_content.vue'
const xlsx = require('xlsx')

export default {
  setup() {
    const forwarderOrders = ref([])
    const ALLOWED_USERS = reactive(new Map([
      ['100218675663923431895', '이지영'],
      ['3737025369', '이지영']
    ]))
    const expandedRows = ref([])
    let isExpandAll = ref(false)
    function onRowExpand (event) {
      expandedRows.value.push({[event.data.IDX]:true}) 
    }
    const expandAll = () => {
      if(isExpandAll.value === true) {
        expandedRows.value = []
      } else {
        expandedRows.value = forwarderOrders.value.reduce((acc, p) => (acc[p.IDX] = true) && acc, {})
      }
      isExpandAll.value = !isExpandAll.value
    }
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 포워더'
    const keywords = '알바,포워더,forwarder,재테크,geek9,직구,해외직구,구매대행,배송대행,핸들러,handler,부업'
    const description = '해외 협력사/포워더 전용 페이지'
    const geek9Url = ssr.GEEK9_URL + 'forwarder'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const cate = ref([
      { label: '포워더' }
    ])
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    return {
      cate, addJsonLd, ALLOWED_USERS, onRowExpand, expandedRows, forwarderOrders, expandAll, isExpandAll
    }
  },
  mounted() {
    this.addJsonLd()
    this.emitter.on('forwarderReload', this.reload)
    this.api.setPage('forwarder')
    console.log(this.api.getCookies('page'))
    this.search({type:'SELECT_USER_BY_TYPE'})
    
    
    // if(this.id === undefined || this.id === '') {
    //   alert('등록된 포워더가 아닙니다')
    //   router.push({name:"contract"})
    // } else {
    //   // TEST
    //   if(this.ALLOWED_USERS.has(this.id)) {
    //     this.userType = Number(this.$route.params.userType)
    //     this.mail = this.$route.params.mail
    //   } else {
    //     alert('등록된 포워더가 아닙니다')
    //     router.push({name:"contract"})
    //   }
    // }
  },
  unmounted() {
    this.emitter.off('forwarderReload', this.reload)
  },
  components: {
    context, forwarderc
  },
  data() { 
    return { 
      page:1,
      fowarderKeyword:'',
      
    }
  },
  methods: {
    async excel() {
      let execelList = Array()
      for(let i=0; i<this.forwarderOrders.length; i++) {
        const item = this.forwarderOrders[i]
        execelList.push({
          DATE:this.api.getDateByFormat((item.REG_DATE).substring(0,8), 'YYYY/MM/DD'),
          IDX:item.IDX, 
          COUNTRY:item.COUNTRY,
          ITEM:item.NAME,
          STATUS:this.api.getOrderStatus(Number(item.STATUS)),
          TRACKING_NUMBER:item.TRACKING_NUMBER,
          URL:item.URL,
          정산여부:(item.NOTICE.indexOf('입금완료') !== -1 || item.NOTICE.indexOf('결제완료') !== -1 || item.NOTICE.indexOf('정산완료') !== -1) ? '정산완료' : '정산전'
        })
      }
      const workBook = xlsx.utils.book_new()
      const workSheet = xlsx.utils.json_to_sheet(execelList)
      xlsx.utils.book_append_sheet(workBook, workSheet, 'geek9.kr')
      xlsx.writeFile(workBook,  'GEEK9_' + this.api.getDateByFormat(new Date(), 'YYYYMMDD') + '.xlsx')
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      await this.api.axiosGql('SELECT_ORDER_BY_FORWARDER', {type:'SELECT_ORDER_BY_FORWARDER', name:this.ALLOWED_USERS.get(this.api.getCookies('id')), page:this.page, keyword:this.fowarderKeyword})
      .then( async (res) => { 
        if(res.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else this.forwarderOrders = res.data 
      })
      .catch( (err) => {
        console.log(err)
      })
    },
    async search(param) {
      const type = param.type
      if(type === 'SELECT_USER_BY_TYPE') {
        this.fowarderKeyword
        const mail = this.api.getCookies('mail')
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), mail: mail, userType:2})
        .then( async (res) => { 
          const json = res.data
          if(json === undefined || json.length === 0 || !this.ALLOWED_USERS.has(this.api.getCookies('id'))) {
            alert('등록된 포워더가 아닙니다')
            router.push({name:"contract"})
          } else
            this.search({type:'SELECT_ORDER_BY_FORWARDER'})
        })
      } else if(type === 'SELECT_ORDER_BY_FORWARDER') {
        param.name = this.ALLOWED_USERS.get(this.api.getCookies('id'))
        param.page = 1
        param.keyword = this.fowarderKeyword
        await this.api.axiosGql(type, param)
        .then( async (res) => { 
          this.forwarderOrders = res.data 
        })
      }
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.forwarderKey++
        this.$emit("setMenu", {page:'forwarder'})
      }
    },
  }
}
</script>
<style scoped>
</style>
