<template>
  <div class="divider" style="background-color: black; height:2px;"/>
    <div id="cart-wrapper" class="mypage-wrapper">
      <div style="width: 150px;">
        <img id='cart-img' class="margin10 link" :src="(imgUrl === '' || undefined) ? './assets/geek9_logo.jpg' : imgUrl"/>
      </div>
      <div id="cart-container" >
        <div class="mypage-cart-wrapper"> 
          <p><label>주문날짜</label>{{ this.api.getDateByFormat(regDate, 'YYYY.MM.DD') }}</p>
          <p>
            <label>진행상태</label>{{ this.api.getOrderStatus(status) }}
            <!-- <Button v-if="status === 2" size="small" label="저장" @click="click({type:'UPDATE_ORDER_TRACKING'})" />  -->
          </p>
          <p><label>정산여부</label>{{ (notice.indexOf('입금완료') !== -1 || notice.indexOf('결제완료') !== -1 || notice.indexOf('정산완료') !== -1) ? '정산완료(' + this.api.getDateByFormat(notice.substring(notice.indexOf('(') + 1, notice.indexOf(')')), 'YYYY/MM/DD') + ')' : '정산전'  }}</p>
        </div>
        <div class="mypage-cart-wrapper"> 
          <p><label>상품링크</label><a :href="url" target="_blank">클릭</a></p>
          <p class="tracking-label">
            <label>추적번호</label>
            <input class="tracking-input" type="text" ref="trackingNumber" :value="trackingNumber" placeholder="Tracking Number" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_TRACKING'})" /> 
          </p>
        </div>
      </div>
    </div>
  <div class="divider" />
</template>
<script>
export default {
  data() { 
    return { 
    }
  },
  props: {
    regDate:String,
    url:String,
    imgUrl:String,
    trackingNumber:String,
    notice:String,
    status:Number,
    idx:Number
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'UPDATE_ORDER_TRACKING') {
        const trackingNumber = this.$refs.trackingNumber.value
        if(trackingNumber === undefined || trackingNumber === '') this.emitter.emit('confirm', {message: '추적번호를 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
        else {
          if(confirm("추적번호를 변경할까요?")) {
            await this.api.axiosGql(type, {type:type, idx:this.idx, trackingNumber: trackingNumber})
            .then( async (res) => { 
              if(res.status === 200) this.emitter.emit('confirm', {message: '변경되었습니다.', header:this.api.getCookies('page'), icon:'pi pi-info-circle'})
              else alert('다시 시도해주세요')
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.mypage-cart-wrapper > p > label {
  color:gray;
  margin-right:10px;
}
.tracking-label {
  display: flex;
  align-items: center;
  width: 90%;
}

.label-text {
  white-space: nowrap;
  margin-right: 10px;
}

.tracking-input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  padding:5px;
  margin-right: 10px;
  min-width: 0; /* 이는 flex item이 너무 작아지는 것을 방지합니다 */
}
</style>